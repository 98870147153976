import React from "react";
import "./styles.css"
import { useState } from 'react';
import Picture from "./Picture";
import images from "./images.js";


const App = () => {
    
    // const Panels = document.querySelectorAll('.panel')
   

    // Panels.forEach((panel) => {
    //     panel.addEventListener('click', () => {
    //         document.querySelector('.active').classList.remove('active');
    //         panel.classList.add('active');
    //     });
    // });

    
    //Panels.forEach((panel) => {
    //    panel.addEventListener('click',() => {
    //        removeActiveClasses()
    //        panel.classList.add('active')
   //     })
 //   })
//
   // function removeActiveClasses() {
    //    Panels.forEach((panel) => {
    //        panel.classList.remove('active')
   //     })
   // }

   const [activeImage, setActiveImage] = useState('image6')
    console.log(activeImage)

    return(
        
            <div className="container">

                {
                    images.map(image => (<Picture picture={image} key={image.id} setActive={setActiveImage} active = {activeImage}/>)) 

                }



{/*                 
                <div className="panel active image6">
                    <h3>Meet the Hunsakers</h3>
                </div>

                <div className="panel image7">
                    <h3>They love to laugh</h3>
                </div>

                <div className="panel image8">
                    <h3>They got married here</h3>
                </div>

                <div className="panel image9">
                    <h3>So happy</h3>
                </div>

                <div className="panel image10">
                    <h3>Look at that rock!</h3>
                </div> */}
            </div>
)}



export default App;