import React from "react";
import { useState } from "react";




const Picture = ({picture, setActive, active}) => {
    console.log( active === picture.id)
    const handleClick = () => {
        setActive(picture.id)
    }

    return(
    <div onClick={() => handleClick()} className={`panel ${picture.id} ${active === picture.id ? 'active': ''}`}>
        <h3>{picture.text}</h3>
    </div>
    )
}


export default Picture